import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function NotFoundPage() {
  const code = `
int main(void) {
    int *p404 = NULL;
    return 0;
}
  `
  return (
    <Layout>
      <Seo title="Page not found" />
        <code>
          <Link to="/" title="Home">
            <pre>{code}</pre>
          </Link>
        </code>
    </Layout>
  )
}

export default NotFoundPage
